import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import "../css/404.scss"
import htmlContent from "raw-loader!./404.html"

const NotFoundPage = () => (
  <Layout className="subpage">
    <SEO title="404: Not found" />
    <Container>
      <Row>
        <Col className="d-flex text-center">
          <div
            className="mx-auto py-5"
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
